const root = document.createElement("div");
const placeholder = new Comment("Drag Placeholder");

Object.assign(root.style, {
  position: "fixed",
  transform: "translate3d(-50%, -50%, 0)",
  "border-radius": "8px",
  "z-index": "10",
  "pointer-events": "none",
});
root.append(placeholder);
document.body.append(root);

/** @type {{target: HTMLElement | null, x: number, y: number, type: string | null, meta: any}} */
const initialData = {
  x: 0,
  y: 0,
  meta: null,
  target: null,
};

const dragData = reactive({ ...initialData });

watchEffect(() => {
  root.style.top = `${dragData.y}px`;
  root.style.left = `${dragData.x}px`;
});

watch(
  () => dragData.target,
  (target, prevTarget) => {
    if (prevTarget === null) {
      prevTarget = placeholder;
    }

    if (target === null) {
      target = placeholder;
      Object.assign(dragData, initialData);
    }

    prevTarget.replaceWith(target);
  },
);

window.addEventListener("mousemove", (e) => {
  if (dragData.target === null) return;

  dragData.x = e.clientX;
  dragData.y = e.clientY;
});

window.addEventListener("mouseup", () => {
  dragData.target = null;
});

export function useDrag() {
  return {
    dragData,
  };
}
