<script setup>
import { icon_google } from '@/assets/icons/common';
import { useUserStore, useSubscriptionInfo } from '@/store/modules/user.ts';
import { useRouter, useRoute } from 'vue-router';
import { useTokenClient } from 'vue3-google-signin';

const props = defineProps({
  pageType: String,
});
const emit = defineEmits(['loginSuccess']);

const globalDomain = inject('globalDomain');
const subscriptionInfo = useSubscriptionInfo();
const user = useUserStore();
const route = useRoute();
const router = useRouter();

const handleOnSuccess = async (response) => {
  if (response.access_token) {
    const res = await user.googleLogin({ accessToken: response.access_token });

    const { code, data } = res;
    if (code === 0) {
      if (data.register) {
        // 首次注册，上报埋点
        window.gr?.('track', 'conversion', { email: data.email });
        // window.fpr?.("referral", { email: data.email });

        if (window.trackdesk) {
          window.trackdesk('similarvideo-boolv', 'externalCid', {
            externalCid: data.email,
            revenueOriginId: 'b0aa776a-2476-4cb4-9c45-2717d37bef7f',
          });
        }

        if (window.tolt_referral) {
          window.tolt.signup(data.email);
        }

        gtag('event', 'registered_success', {
          email: data.email,
        });
      }

      await subscriptionInfo.refresh();

      console.log('props.pageType2222 ', props.pageType);

      if (props.pageType == 'modal') {
        emit('loginSuccess');
        return;
      }

      const redirect = route.query?.redirect;
      if (redirect) {
        router.push({ path: route.query.redirect });
      } else {
        router.push('/workspace');
      }
    }
  }
};

const handleOnError = (errorResponse) => {
  console.log('Error: ', errorResponse);
};

const { isReady, login } = useTokenClient({
  onSuccess: handleOnSuccess,
  onError: handleOnError,
  // other options
});
</script>
<template>
  <div class="container-google-login">
    <el-button
      type="primary"
      class="google-login-button"
      @click.stop="() => login()"
    >
      <SvgIcon
        name="icon_google"
        :size="24"
        style="margin-right: 20px"
      ></SvgIcon>

      Continue with Google
    </el-button>
    <div class="pt-5">
      <el-divider
        class="google-login-divider text-inc-color border-t-border-color my-8"
        >or</el-divider
      >
    </div>
  </div>
</template>
<style lang="scss" scoped>
.container-google-login {
  width: 100%;
}
.google-login-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 54px;
  border-radius: 36px;
  background: #060606;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #060606;
  color: #ffffff;
  margin: 0 auto;
  font-weight: 400;

  img {
    margin-right: 20px;
  }

  &:hover {
    background: #2f2f2f;
  }
}

.google-login-divider {
  border-top-color: #dee0e3;
  margin: 28.5px 0;

  :deep(.el-divider__text) {
    color: #8f959e;
    font-size: 16px;
  }
}
</style>
