function getComputedSize(element) {
  const style = getComputedStyle(element);
  const width = style.getPropertyValue("width");
  const height = style.getPropertyValue("height");

  return [parseFloat(width), parseFloat(height)];
}

let handler;
const drag = {
  created(element, binding) {
    handler = (e) => {
      const file = binding.arg;
      const cover = binding.modifiers.cover;
      const target = e.currentTarget;
    
      if ((file.state && file.state !== 0) || e.button !== 0) {
        return;
      }
      const mouseUp = () => {
        binding.value("mouseup", e, file);
        target.removeEventListener("mousemove", mouseMove);
      };
      const mouseMove = (e) => {
        target.removeEventListener("mouseup", mouseUp);
        const element = cover ? target.querySelector(".cover") : target;
        const [width, height] = getComputedSize(element);
        const dragTarget = element.cloneNode(true);
    
        Object.assign(dragTarget.style, {
          width: `${width}px`,
          height: `${height}px`,
          borderRadius: "4px",
          backgroundColor: "rgba(100,100,100,.12)",
        });
        binding.value("mousemove", e, file, dragTarget);
      };
      target.addEventListener("mousemove", mouseMove, { once: true });
      target.addEventListener("mouseup", mouseUp, { once: true });
    }
    element.addEventListener("mousedown", handler);
  },
  beforeUnmount(element) {
    if (handler) {
      element.removeEventListener("mousedown", handler);
    }
  },
};
export default drag;
